import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './Name.module.scss';

import names from '../../data/names/navn';
import generatedBoysNames from '../../data/names/generert-guttenavn';
import generatedGirlsNames from '../../data/names/generert-jentenavn';
import generatedNames from '../../data/names/generert-navn';

import { functions } from '../../utils/firebase';
import { httpsCallable } from 'firebase/functions';

import {
  MdFemale,
  MdMale,
  MdTransgender,
  MdOutlineStarBorder,
  MdOutlineStar,
  MdVolumeUp,
  MdVolumeOff,
  MdBookmark,
  MdClose,
} from 'react-icons/md';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';

const Name = () => {
  const requestPrompt = httpsCallable(functions, 'requestPromptOld');

  const [dark, setDark] = useState(false);
  const [loadingName, setLoadingName] = useState(true);
  const [loadingMeaning, setLoadingMeaning] = useState(true);
  const [muted, setMuted] = useState(true);
  const [open, setOpen] = useState(false);

  const [name, setName] = useState(null);
  const [meaning, setMeaning] = useState(null);
  const [double, setDouble] = useState(false);
  const [saved, setSaved] = useState(false);
  const [gender, setGender] = useState('both');

  const cleanNames = useRef([]);
  const savedNames = useRef([]);

  const generateName = useCallback(() => {
    const getRandomName = () => {
      return cleanNames.current[
        Math.round(Math.random() * (cleanNames.current.length - 1))
      ];
    };
    setOpen(false);
    let newName = getRandomName();
    if (double) {
      newName += ` ${getRandomName()}`;
    }
    setName(newName);
  }, [double]);

  const saveName = useCallback(() => {
    if (name) {
      if (!saved) {
        savedNames.current.push(name);
        setSaved(true);
      } else {
        savedNames.current = savedNames.current.filter((e) => e !== name);
        setSaved(false);
      }
      localStorage.setItem('savedNames', JSON.stringify(savedNames.current));
    }
  }, [name, saved]);

  const voiceName = useCallback(() => {
    if (name) {
      const msg = new SpeechSynthesisUtterance();
      msg.lang = 'no-NO';
      msg.text = name;
      window.speechSynthesis.speak(msg);
    }
  }, [name]);

  useEffect(() => {
    if (name) {
      setLoadingMeaning(true);
      requestPrompt({
        type: 'name',
        input: [name],
      }).then((result) => {
        const data = result.data;
        setLoadingMeaning(false);

        if (data.type === 'success') {
          setMeaning(data.text);
        } else {
          setMeaning('');
        }
      });
    }
  }, [name]);

  useEffect(() => {
    const localSavedNames = localStorage.getItem('savedNames');
    if (localSavedNames) {
      savedNames.current = JSON.parse(localSavedNames);
    }
  }, []);

  useEffect(() => {
    if (savedNames.current.includes(name)) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [name]);

  useEffect(() => {
    if (name && !muted) {
      voiceName();
    }
  }, [name, muted, voiceName]);

  useEffect(() => {
    if (name !== null) {
      setLoadingName(false);
    }
  }, [name]);

  useEffect(() => {
    const keydown = (e) => {
      if (e.key === 'Enter') {
        generateName();
      } else if (e.key === ' ') {
        saveName();
      } else if (e.key === '2') {
        setDouble(!double);
      } else if (e.key === 'd') {
        setDark(!dark);
      }
    };
    window.addEventListener('keyup', keydown);
    return () => {
      window.removeEventListener('keyup', keydown);
    };
  }, [generateName, saveName, double, dark]);

  useEffect(() => {
    let nameList = generatedNames;
    if (gender === 'boy') {
      nameList = generatedBoysNames;
    } else if (gender === 'girl') {
      nameList = generatedGirlsNames;
    }
    cleanNames.current = [
      ...new Set(
        nameList
          .map((name) => {
            if (names.includes(name)) {
              return null;
            } else {
              return name.toLocaleLowerCase();
            }
          })
          .filter((name) => {
            return name !== null && name.length >= 3;
          })
      ),
    ];
    generateName();
  }, [generateName, gender]);

  const OptionKey = ({ option, label }) => {
    return (
      <Button
        inverted={dark}
        ghost={gender !== option}
        onClick={() => {
          setGender(option);
        }}
        icon={label}
      />
    );
  };

  const savedNameList = savedNames.current.map((name) => {
    return (
      <span
        key={name}
        onClick={() => {
          setOpen(false);
          setName(name);
        }}
      >
        {name}
      </span>
    );
  });

  if (loadingName) {
    return (
      <main className={styles.app}>
        <Heading size={5}>Loading</Heading>
      </main>
    );
  } else {
    return (
      <main className={classNames(styles.app, { [styles.dark]: dark })}>
        <header>
          <Button
            inverted={dark}
            ghost
            onClick={() => {
              setMuted(!muted);
            }}
            icon={muted ? MdVolumeOff : MdVolumeUp}
          />
          <section className={styles.options}>
            <OptionKey option={'girl'} label={MdFemale} />
            <OptionKey option={'both'} label={MdTransgender} />
            <OptionKey option={'boy'} label={MdMale} />
          </section>
          <Button
            inverted={dark}
            className={styles.open}
            ghost
            onClick={() => {
              setOpen(!open);
            }}
            icon={open ? MdClose : MdBookmark}
          />
        </header>

        <section className={styles.generator}>
          <div className={styles.result}>
            <Heading className={styles.name}>{name}</Heading>
            <p>
              {loadingMeaning
                ? `AI-Generating the meaning of this name...`
                : meaning}
            </p>
          </div>
          <section className={styles.options}>
            <Button
              inverted={dark}
              ghost={!saved}
              onClick={saveName}
              icon={saved ? MdOutlineStar : MdOutlineStarBorder}
            />
            <Button
              inverted={dark}
              ghost
              onClick={voiceName}
              icon={MdVolumeUp}
            />
          </section>
        </section>
        <footer>
          <section className={styles.options}>
            <Button inverted={dark} onClick={generateName} large>
              AI-Generate a new name
            </Button>
          </section>
          <p>
            Made by{' '}
            <a href={'https://www.linkedin.com/in/alexander-solbakken/'}>
              Alex
            </a>{' '}
            @ <a href={'https://www.try.no'}> TRY Creative Tech</a> with a
            custom trained RNN, taught on{' '}
            <a
              href={
                'https://storage.googleapis.com/davinci-ai-trainingdata/names.txt'
              }
            >
              this curated list of Norwegian names
            </a>{' '}
            along with{' '}
            <a href={'https://openai.com/api/'}>
              OpenAIs GPT-3 Davinci text API
            </a>
            . Toggle darkmode{' '}
            <span
              onClick={() => {
                setDark(!dark);
              }}
            >
              here
            </span>
            .
          </p>
        </footer>
        <section className={classNames(styles.panel, { [styles.open]: open })}>
          {savedNames.current.length > 0 ? (
            <div className={styles.list}>{savedNameList}</div>
          ) : (
            <p>Your saved names will appear here</p>
          )}
        </section>
      </main>
    );
  }
};

export default Name;
